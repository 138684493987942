import {
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { LOCALE } from "../../properties/Locale";

export default function RequestMarriageWizardPayment({
  getSpouseIndex,
  formData,
}) {
  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ paddingTop: "10px" }}>
        {formData?.id &&
          formData?.status == "PRICE_REQUEST" &&
          formData?.paymentStatus == "PAID" && (
            <React.Fragment>
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={12} md={8}>
                <Alert>{LOCALE.payment_price_request}</Alert>
              </Grid>{" "}
              <Grid item xs={false} md={2}></Grid>
            </React.Fragment>
          )}
        {formData.id &&
          (formData.status == "NEW" ||
            formData.status == "PRICE_REQUEST_APPROVED" ||
            (formData?.status == "PRICE_REQUEST" &&
              formData?.paymentStatus == "UNPAID")) && (
            <React.Fragment>
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={12} md={8}>
                <Alert severity="info">
                  {LOCALE.payment_terms_agreement_text}
                </Alert>
              </Grid>
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={8}>
                <Alert severity="warning">
                  <b>{LOCALE.refund_policy}</b>
                </Alert>
              </Grid>
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={12} md={8}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {LOCALE.requestType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {LOCALE.service}
                        </TableCell>
                        <Hidden xsDown>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            {LOCALE.administrative_tax}
                          </TableCell>
                        </Hidden>
                        <Hidden xsDown>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "bold" }}
                          >
                            {LOCALE.commission}
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {LOCALE.schedule_marriage_between +
                            ` ${
                              formData?.spousesData[getSpouseIndex("GROOM")]
                                ?.name
                            } ${
                              formData?.spousesData[getSpouseIndex("GROOM")]
                                ?.surname
                            } ${LOCALE.with_EMBG} ${
                              formData?.spousesData[getSpouseIndex("GROOM")]
                                ?.embg
                            } и 
                        ${
                          formData?.spousesData[getSpouseIndex("BRIDE")]?.name
                        } ${
                              formData?.spousesData[getSpouseIndex("BRIDE")]
                                ?.surname
                            } ${LOCALE.with_EMBG} 
                        ${
                          formData?.spousesData[getSpouseIndex("BRIDE")]?.embg
                        }`}
                        </TableCell>
                        <TableCell align="center">
                          {formData.status == "PRICE_REQUEST_APPROVED"
                            ? formData?.price
                              ? formData?.price
                              : "0"
                            : "0"}
                        </TableCell>
                        <Hidden xsDown>
                          <TableCell align="center">
                            {formData.status == "PRICE_REQUEST_APPROVED"
                              ? "0"
                              : "50"}
                          </TableCell>
                        </Hidden>
                        <Hidden xsDown>
                          <TableCell align="center">9%</TableCell>
                        </Hidden>
                      </TableRow>
                      {formData?.requestType == "REGULAR_OUTSIDE" &&
                        formData.status == "PRICE_REQUEST_APPROVED" && (
                          <>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Извод од матичната книга на венчани
                              </TableCell>
                              <TableCell align="center">50</TableCell>
                              <TableCell align="center">100</TableCell>
                              <TableCell align="center">9%</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Извод од матичната книга на родени за
                                младоженецот
                              </TableCell>
                              <TableCell align="center">50</TableCell>
                              <TableCell align="center">100</TableCell>
                              <TableCell align="center">9%</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Извод од матичната книга на родени за невестата
                              </TableCell>
                              <TableCell align="center">50</TableCell>
                              <TableCell align="center">100</TableCell>
                              <TableCell align="center">9%</TableCell>
                            </TableRow>
                          </>
                        )}
                      <TableRow>
                        <Hidden xsDown>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell align="center"></TableCell>
                        </Hidden>
                        <TableCell align="center">
                          {LOCALE.commission}
                        </TableCell>
                        <TableCell align="center">
                          {Math.round(
                            (+formData?.price + 150 + 150 + 150) * 0.09
                          ) < 150
                            ? Math.round(
                                (+formData?.price + 150 + 150 + 150 + 150) *
                                  0.09
                              )
                            : 150}
                        </TableCell>
                      </TableRow>

                      <Hidden smUp>
                        <TableRow>
                          <Hidden xsDown>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell align="center"></TableCell>
                          </Hidden>
                          <TableCell align="center">
                            {LOCALE.administrative_tax}
                          </TableCell>
                          <TableCell align="center">
                            {formData?.administrativeFee}
                          </TableCell>
                        </TableRow>
                      </Hidden>
                      <TableRow>
                        <Hidden xsDown>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell align="center"></TableCell>
                        </Hidden>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {LOCALE.total_amount}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {formData.status == "PRICE_REQUEST_APPROVED"
                            ? Math.round(
                                (+formData?.price + 150 + 150 + 150) * 0.09
                              ) > 150
                              ? Math.round(
                                  +formData?.price + 150 + 150 + 150 + 150
                                )
                              : Math.round(
                                  (+formData?.price + 150 + 150 + 150) * 1.09
                                )
                            : 60}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>{" "}
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={false} md={2}></Grid>
              <Grid item xs={12} md={8}>
                <Alert
                  style={{ cursor: "help" }}
                  onClick={() =>
                    window.open(
                      "/PaymentPolicy.pdf",
                      "_blank" // <- This is what makes it open in a new window.
                    )
                  }
                >
                  {LOCALE.payment_policy}
                </Alert>
              </Grid>{" "}
              <Grid item xs={false} md={2}></Grid>
            </React.Fragment>
          )}
      </Grid>
    </React.Fragment>
  );
}
